@tailwind base;
@tailwind components;

@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro:normal,bold,semibold);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:normal,bold,semibold);

@tailwind utilities;

@layer utilities {
  @variants responsive {
    .title-letter-spacing {
      letter-spacing: 4px;
    }
  }
}