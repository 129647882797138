.navbar-items {
  cursor: pointer;
  padding: 1rem 1rem 0.8rem 1rem;
  border-bottom-width: 1px;
  border-color: rgba(145, 226, 220, 0);
  transition: all 200ms ease-in-out;
}

.navbar-items:hover {
  border-color: rgba(145, 226, 220, 1);
  border-radius: 0.125rem;
  background-color: rgba(145, 226, 220, 0.2);
}

.navbar-items-selected {
  border-radius: 0.125rem;
  /* border-radius: 0.5rem; */
  border-color: rgba(145, 226, 220, 1) !important;
}

.navbar-items-parent {
  cursor: pointer;
  padding: 1rem 1rem 0.8rem 1rem;
  border-bottom-width: 1px;
  border-color: rgba(145, 226, 220, 0);
  transition: all 200ms ease-in-out;
}

.navbar-items-parent:hover {
  cursor: pointer;
  padding: 1rem 1rem 0.8rem 1rem;
  background-color: rgba(145, 226, 220, 0.2);
  transition: all 200ms ease-in-out;
}

.fade-in {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)
}

#right-wave {
  position: absolute;
  right: 0;
  top: -400px;
  clip-path: ellipse(60% 30% at 100% 50%);
  height: 1600px;
  width: 1100px;
  opacity: 0.4;
}

#right-wave-2 {
  position: absolute;
  right: 0;
  top: -300px;
  clip-path: ellipse(60% 30% at 100% 50%);
  height: 1500px;
  width: 1000px;
  opacity: 0.5;
}

#left-wave {
  position: absolute;
  left: 0;
  top: -350px;
  clip-path: ellipse(60% 30% at 0% 50%);
  height: 1200px;
  width: 700px;
  opacity: 0.4;
}

#left-wave-2 {
  position: absolute;
  left: 0;
  top: -300px;
  clip-path: ellipse(60% 30% at 0% 50%);
  height: 1200px;
  width: 700px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-y: 0%;
  background-position-x: 180%;
}

#left-wave-scientists {
  position: absolute;
  left: 0;
  top: -300px;
  clip-path: ellipse(60% 30% at 0% 50%);
  height: 1300px;
  width: 1100px;
  opacity: 0.4;
}

#left-wave-2-scientists {
  position: absolute;
  left: 0;
  top: -200px;
  clip-path: ellipse(60% 30% at 0% 50%);
  height: 1200px;
  width: 1000px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 20%;
  background-position-x: -70%;
}

#scroll-to-top:hover svg {
  animation: bounce_custom 600ms ease-in-out infinite alternate;
}

@keyframes bounce_custom {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.ul-dots ul {
    list-style-type: disc; list-style-position: inside;
}